import { GatsbySeo } from 'gatsby-plugin-next-seo'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h2 className="title is-spaced has-text-midnightBlue is-size-4-mobile">
            Thanks for registering your sample.
          </h2>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Getting your results</h3>

          <div className="content">
            <p>
              Your result will usually be available within 48 hours of our receiving your sample in the laboratory. We
              will email it to the address you verified with us.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
